<template>
  <div class="main-conent main-conent-minheight form-footer">

    <!-- <page-header into="">
      <strong></strong>
    </page-header> -->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基础信息" name="first">
          <el-card shadow="never" class="border-none margin-t24">
            <div slot="header">
              <span></span>
            </div>
            <el-row :gutter="24">
              <el-col>
                <el-form-item label="分类名称" prop="title">
                  <el-input v-model="ruleForm.title"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="是否开启" prop="enable">
                  <el-switch style="display: block" v-model="ruleForm.enable" active-color="#13ce66"
                    inactive-color="#ff4949" active-text="启用" inactive-text="禁用">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="排序" prop="sort">
                  <el-input-number v-model="ruleForm.sort"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
        </el-tab-pane>
      </el-tabs>

    </el-form>

    <form-footer-toolbar class="text-right">
      <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
    </form-footer-toolbar>

  </div>

</template>
<script>
import store from '@/store';
import FormFooterToolbar from '@/layout/components/FormFooterToolbar';
import { getModel, saveModel } from '@/service/article_category.js';
import E from "wangeditor";
export default {
  components: {
    FormFooterToolbar
  },
  data() {
    return {
      activeName: 'first',
      //lists image media table textcolor wordcount contextmenu preview
      updateUrl: process.env.VUE_APP_APIHOST + 'file/uploadimg',
      ruleForm: {
        id: 0,
        title: '',
        sort: 99,
        enable: true,
        parentId: 0,
        type: 0
      },
      rules: {
      }
    };
  },
  mounted() {

  },

  created() {
    if (this.$route.query.id) {
      this.ruleForm.id = this.$route.query.id;
      this.dataBinding();
    }
    if (this.$route.query.type) {
      this.ruleForm.type = this.$route.query.type;
    }
  },
  methods: {
    dataBinding() {
      getModel(this.ruleForm.id).then(res => {
        this.ruleForm = res;

      });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.isDraft = false;
          saveModel(this.ruleForm).then(res => {
            if (res === true) {
              this.$router.go(-1);
            }
            else {
              this.$message("保存异常");
            }
          });
        } else {
          return false;
        }
      });
    },
    handleUrlSuccess(res) {
      this.ruleForm.url = res.data;
    },
    handleAvatarSuccess(res) {
      this.ruleForm.avatar = res.data;
    },
    handleShareUrlSuccess(res) {
      this.ruleForm.shareUrl = res.data;
    },
    beforeAvatarUpload(file) {
      const isIMG = (file.type === 'image/jpeg' || file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isIMG) {
        this.$message.error('上传头像图片只能是 JPG或者PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return isIMG && isLt2M;
    }
  }
};
</script>
<style lang="scss">
.ql-editor {
  min-height: 300px;
}
</style>